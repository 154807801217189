import React, { Dispatch, useEffect, useState } from "react";
import CustomModal from "../modal";
import Button from "../button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import useJobs from "../../hooks/useJobs";
import {
  GetJobDetailsResponseBody,
  IItem,
  IJobCandidate,
} from "../../types/JobType";
import { useLocation } from "react-router-dom";
import {
  doesStatusAllowEditing,
  generateExcelFileBasedOnQuestionaireData,
} from "./viewJobModalFunctions";
import {
  MultiFileDownloadWithBuildingNotFoundAttachments,
  MultiFileDownloadWithExcel,
} from "../../utils/fileDownloadUtils/MultiFileDownloadUtil";
import ModalPopup from "../modalPopUp";
import Loading from "../loading";
import {
  capitalizeFirstletter,
  removeWhiteSpaceAndReplaceWithDash,
} from "../../utils/stringUtils/StringUtil";
import StarComponent from "../star";

type DownloadErrorMessage = {
  title: string;
  message: string;
};

type StatusType =
  | "open"
  | "pending"
  | "approved"
  | "accepted"
  | "in-progress"
  | "submitted"
  | "withdraw"
  | "cancelled";
type StatusDateKeyType = {
  open: "openDate";
  pending: "pendingDate";
  approved: "approvedDate";
  accepted: "acceptedDate";
  "in-progress": "inProgressDate";
  submitted: "submittedDate";
  withdraw: "withdrawDate";
  cancelled: "cancelledDate";
};

const ViewJobModal = ({
  item,
  showViewJobModal,
  setShowViewJobModal,
  setShowCreateJobModal,
  setModalJobMode,
  updateAccess,
}: {
  item: IItem | undefined;
  updateAccess: boolean;
  showViewJobModal: boolean;
  setShowViewJobModal: Dispatch<React.SetStateAction<boolean>>;
  setShowCreateJobModal: React.Dispatch<React.SetStateAction<boolean>>;
  setModalJobMode: React.Dispatch<
    React.SetStateAction<"Create" | "Edit" | "Draft">
  >;
}) => {
  const location = useLocation();
  const [allowEditing, setAllowEditing] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [jobDetailsData, setJobDetailsData] =
    useState<GetJobDetailsResponseBody>();
  const [downloadError, setDownloadError] = useState<DownloadErrorMessage>();
  const { getJobsDataById } = useJobs();

  const statusDateTime = () => {
    let status: StatusType = location.pathname
      .split("/")
      .slice(-1)[0] as StatusType;
    let statusDateKey: StatusDateKeyType = {
      open: "openDate",
      pending: "pendingDate",
      approved: "approvedDate",
      accepted: "acceptedDate",
      "in-progress": "inProgressDate",
      submitted: "submittedDate",
      withdraw: "withdrawDate",
      cancelled: "cancelledDate",
    };
    const dateValue = jobDetailsData
      ? jobDetailsData[statusDateKey[status]]?.toString()
      : "";
    return (
      dateValue && (
        <div className="block">
          <label className="text-sm text-orange font-medium mr-2">
            {capitalizeFirstletter(status)} Status Date:
          </label>
          <label className="text-sm text-orange">{dateValue ?? "NA"}</label>
        </div>
      )
    );
  };

  useEffect(() => {
    statusDateTime();
    const itemId = item?.id ?? 0;
    if (itemId !== 0 && showViewJobModal) {
      getJobsDataById(itemId).then((res: any) => {
        setJobDetailsData(res);
        console.log(res);
      });
    }

    setAllowEditing(doesStatusAllowEditing(location.pathname));
  }, [item?.id]);

  return (
    <>
      {isLoading && <Loading />}
      {downloadError && (
        <ModalPopup
          visible={!!downloadError.title || !!downloadError.message}
          onClose={() => setDownloadError({ title: "", message: "" })}
          title={downloadError?.title}
          content={<p>{downloadError.message}</p>}
        />
      )}
      <CustomModal
        width={"60%"}
        title={""}
        open={true}
        onOk={() => {}}
        onCancel={() => {
          setShowViewJobModal(false);
        }}
        footer={null}
      >
        <div className="border-gray p-4">
          <div>
            <div className="grid grid-cols-2 gap-4 bg-white">
              <div className="col-span-1 border-r-4 border-gray">
                <div className="block">
                  <label className="text-sm text-bold text-slate-300 font-medium">
                    JOB ID:
                  </label>
                  <label className="text-sm text-slate-300 font-medium">
                    {" ".concat(jobDetailsData?.jobIdStr ?? "NA")}
                  </label>
                </div>
                <div className="block">
                  <label className="text-sm text-orange font-medium mr-2">
                    Status:
                  </label>
                  <label className="text-sm text-orange">
                    {jobDetailsData?.status.name}
                  </label>
                </div>
                {statusDateTime()}
                {!location.pathname.includes("completed") ? (
                  <div className="block">
                    <label className="text-sm text-orange font-medium mr-2">
                      Target Completion Date:
                    </label>
                    <label className="text-sm text-orange">
                      {jobDetailsData?.targetCompletionDate?.toString() ?? "NA"}
                    </label>
                  </div>
                ) : (
                  <div className="block">
                    <label className="text-sm text-orange font-medium mr-2">
                      Completion Date:
                    </label>
                    <label className="text-sm text-orange">
                      {jobDetailsData?.completionDate?.toString() ?? "NA"}
                    </label>
                  </div>
                )}
                <div className="block">
                  <label className="text-sm text-orange font-medium mr-2">
                    Estimated Payment:
                  </label>
                  <label className="text-sm text-orange">
                    {jobDetailsData?.estimatedPayment}
                  </label>
                </div>
                <div className="block">
                  <label className="text-sm text-orange font-medium mr-2">
                    Other Details:
                  </label>
                  <label className="text-sm text-orange">
                    {jobDetailsData?.otherDetails}
                  </label>
                </div>
                <div className="block">
                  <label className="text-sm text-orange font-medium mr-2">
                    On-Site Instructions:
                  </label>
                  <label className="text-sm text-orange">
                    {jobDetailsData?.onSiteInstructions}
                  </label>
                </div>
                <div className="block">
                  <label className="text-sm text-orange font-medium mr-2">
                    Discreet:
                  </label>
                  <label className="text-sm text-orange">
                    {jobDetailsData?.isDiscreet ? "Yes" : "No"}
                  </label>
                </div>
                {jobDetailsData?.reasonForResubmission && (
                  <div className="block">
                    <label className="text-sm text-orange font-medium mr-2">
                      Resubmission Reason:
                    </label>
                    <label className="text-sm text-orange">
                      {jobDetailsData?.reasonForResubmission}
                    </label>
                  </div>
                )}
              </div>

              <div className="col-span-1 ml-4">
                <div className="block">
                  <label className="text-sm text-slate-300 font-medium">
                    Details
                  </label>
                </div>
                <div className="block">
                  <label className="text-sm text-slate-300 font-medium">
                    {jobDetailsData?.companyName}
                  </label>
                  <div className="block">
                    <label className="text-sm text-slate-300">
                      {jobDetailsData?.jobAddressInformation?.address1
                        .concat(", ")
                        .concat(
                          jobDetailsData?.jobAddressInformation?.address2
                        ) ?? "NA"}
                    </label>
                    <br />
                    <label className="text-sm text-slate-300">
                      {jobDetailsData?.jobAddressInformation?.country}
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4 bg-white p-16"></div>

            <div className="grid grid-cols-2 row-span-2 gap-4 bg-white">
              <div className="col-span-1 border-r-4 border-gray">
                <div className="block">
                  <label className="text-sm text-orange font-medium mr-2">
                    Site Verifier
                  </label>
                </div>
                <div className="block pb-4">
                  <label className="text-sm text-slate-300 font-medium">
                    Username
                  </label>
                  <div>
                    <label className="text-sm text-slate-300">
                      {jobDetailsData?.jobCandidates?.find(
                        (val: IJobCandidate) => val.isApprovedByAdmin
                      )?.user?.name ||
                        jobDetailsData?.jobCandidates?.find(
                          (val: IJobCandidate) => val.status.id === 8
                        )?.user?.name ||
                        "NA"}
                    </label>
                  </div>
                  <label className="text-sm text-slate-300 font-medium">
                    Country
                  </label>
                  <div>
                    <label className="text-sm text-slate-300">
                      {jobDetailsData?.jobCandidates?.find(
                        (val: IJobCandidate) => val.isApprovedByAdmin
                      )?.user?.userAddressInformation?.country ||
                        jobDetailsData?.jobCandidates?.find(
                          (val: IJobCandidate) => val.status.id === 8
                        )?.user?.userAddressInformation.country ||
                        "NA"}
                    </label>
                  </div>
                </div>
                <div className="block pb-4">
                  <label className="text-sm text-slate-300 font-medium mr-2">
                    Registered:
                  </label>
                  <div>
                    <label className="text-sm text-slate-300">
                      {jobDetailsData?.jobCandidates
                        ?.find((val: IJobCandidate) => val.isApprovedByAdmin)
                        ?.user?.registeredAt?.toString() ||
                        jobDetailsData?.jobCandidates
                          ?.find((val: IJobCandidate) => val.status.id === 8)
                          ?.user?.registeredAt?.toString() ||
                        "NA"}
                    </label>
                  </div>
                </div>
                <div className="block pb-4">
                  <label className="text-sm text-slate-300 font-medium mr-2">
                    Expected Visit Date:
                  </label>
                  <div>
                    <label className="text-sm text-slate-300">
                      {jobDetailsData?.proposedAppointmentDate?.toString() ??
                        "NA"}
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-span-1 row-span-1 ml-4">
                {location.pathname.includes("/completed") && (
                  <div className="block">
                    <div className="block mb-3">
                      <label className="text-sm text-slate-300 font-medium">
                        SV's Job Rating
                      </label>
                      <div className="w-100 mb-1">
                        <StarComponent
                          labelText="Responsiveness"
                          ratingValue={jobDetailsData?.rating?.responsiveness}
                          setStarRating={() => {}}
                          disabled
                        />
                      </div>
                      <div className="w-100">
                        <StarComponent
                          labelText="Job Quality"
                          ratingValue={jobDetailsData?.rating?.quality}
                          setStarRating={() => {}}
                          disabled
                        />
                      </div>
                      <div>
                        <StarComponent
                          labelText="Delivery"
                          ratingValue={jobDetailsData?.rating?.delivery}
                          setStarRating={() => {}}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="block mb-3">
                      <label className="text-sm text-slate-300 font-medium">
                        Downloads
                      </label>
                    </div>
                    <div className="mb-1">
                      <Button
                        children={"Questionaire.xlsx"}
                        variant={"download"}
                        type="button"
                        onClick={() => {
                          generateExcelFileBasedOnQuestionaireData({
                            excelFileName: `questionnaire-${removeWhiteSpaceAndReplaceWithDash(
                              jobDetailsData?.jobIdStr!,
                              jobDetailsData?.id.toString()!
                            )}.xlsx`,
                            questionaireData: jobDetailsData?.questionaire!,
                            observationData: jobDetailsData?.observation!,
                          });
                        }}
                      />
                    </div>
                    <div className="mb-1">
                      <Button
                        children={"Attachments.zip"}
                        variant={"download"}
                        type="button"
                        onClick={() => {
                          MultiFileDownloadWithExcel({
                            zipFileName: `${removeWhiteSpaceAndReplaceWithDash(
                              jobDetailsData?.jobIdStr!,
                              jobDetailsData?.id.toString()!
                            )}-${removeWhiteSpaceAndReplaceWithDash(
                              jobDetailsData?.companyName!,
                              "unknown-company"
                            )}`,
                            setIsLoading: setIsLoading,
                            attachmentNames:
                              jobDetailsData?.buildingAttachment!,
                            setDownloadError: setDownloadError,
                          });
                        }}
                      />
                    </div>
                    {jobDetailsData?.buildingNotFoundQuestionaire !== null && (
                      <div className="mb-1">
                        <Button
                          children={"BuildingNotFound.zip"}
                          variant={"download"}
                          type="button"
                          onClick={() => {
                            MultiFileDownloadWithBuildingNotFoundAttachments({
                              zipFileName: `${removeWhiteSpaceAndReplaceWithDash(
                                jobDetailsData?.jobIdStr!,
                                jobDetailsData?.id.toString()!
                              )}-${removeWhiteSpaceAndReplaceWithDash(
                                jobDetailsData?.companyName!,
                                "unknown-company"
                              )}-buildingNotFound`,
                              setIsLoading: setIsLoading,
                              buildingNotFoundAttachment:
                                jobDetailsData?.buildingNotFoundQuestionaire!,
                              setDownloadError: setDownloadError,
                            });
                          }}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>

              <div className="col-span-1 row-span-1 ml-4">
                <div className="block"></div>
              </div>

              <div className="flex justify-end gap-4 bg-white">
                <div className="basis-3/6 ml-4">
                  <div className="">
                    <Button
                      children={"Close"}
                      variant={"primaryorange"}
                      onClick={() => {
                        setShowViewJobModal(false);
                      }}
                    />
                  </div>
                </div>

                {allowEditing && updateAccess && (
                  <div className="basis-3/6 ml-4">
                    <div className="block">
                      <Button
                        children={[
                          <FontAwesomeIcon
                            key={
                              jobDetailsData?.id! +
                              jobDetailsData?.companyName! +
                              jobDetailsData?.status.name
                            }
                            icon={faPen}
                            className="mr-2"
                          />,
                          "Edit Job",
                        ]}
                        variant={"edit"}
                        onClick={() => {
                          setShowCreateJobModal(true);
                          setShowViewJobModal(false);
                          if (location.pathname.includes("/draft")) {
                            setModalJobMode("Draft");
                          } else {
                            setModalJobMode("Edit");
                          }
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default ViewJobModal;
