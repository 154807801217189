import React, { useEffect, useState } from "react";

const StarComponent = ({
  labelText,
  setStarRating,
  disabled,
  ratingValue,
}: {
  labelText: string;
  setStarRating: React.Dispatch<React.SetStateAction<number>>;
  disabled?: boolean;
  ratingValue?: number;
}) => {
  const [rating, setRating] = useState<any>(null);
  const [hover, setHover] = useState<any>(null);
  const [totalStars, setTotalStars] = useState(5);

  const handleStarRatingChange = (currentRating: any) => {
    if (!disabled && currentRating !== 0) {
      setStarRating(currentRating);
    }
  };

  useEffect(() => {
    setRating(ratingValue);
  }, [ratingValue]);

  return (
    <>
      <div
        className="w-100 grid grid-cols-5 items-center"
        data-testid="star-component-wrapper"
      >
        <label className="col-span-1" data-testid="star-component-label">
          {labelText}
        </label>
        <div className="col-span-4" data-testid="star-component-star-wrapper">
          {[...Array(totalStars)].map((star, index) => {
            const currentRating = index + 1;

            return (
              <label key={index}>
                <input
                  type="radio"
                  name="rating"
                  value={currentRating}
                  onChange={(e) => {
                    if (!disabled) {
                      handleStarRatingChange(currentRating);
                      setRating(currentRating);
                    }
                  }}
                />
                <span
                  className="star"
                  style={{
                    color:
                      currentRating <= (hover || rating)
                        ? "#ffc107"
                        : "#e4e5e9",
                  }}
                  onMouseEnter={() => {
                    if (!disabled) {
                      setHover(currentRating);
                    }
                  }}
                  onMouseLeave={() => {
                    setHover(null);
                  }}
                  data-testid="star-component-star-star"
                >
                  &#9733;
                </span>
              </label>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default StarComponent;
